@import "./style/variables";
@import "./style/mixins";


:root {
  
  overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    overflow-x: hidden;
    width: auto;
  
    cursor: url("./assets/vectors/cursors/cursor-black.svg"), pointer !important;
  }
  * {
    -webkit-tap-highlight-color: transparent;
  }
/* custom scrollbar */

::-webkit-scrollbar {
  width: 10px;
 margin: 0;
 padding: 0;
}


::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;

}


::-webkit-scrollbar-thumb {
  background: $color-white; 
  border-radius: 10px;
 
}


::-webkit-scrollbar-thumb:hover {
  background: $color-yellow; 

}

//--------
// General

.selecting-cursor {
  .App {
    cursor: url(./assets/vectors/cursors/cursor-blue.svg), pointer !important;

  }
}

.link-hover, a , .hamburger svg,::-webkit-scrollbar-thumb:hover, .clickable {
  cursor: url(./assets/vectors/cursors/cursor-blue.svg), pointer !important;
}

.button-hover, button {
  cursor: url(./assets/vectors/cursors/cursor-blue.svg), pointer !important;
}

.btn {
  margin-top: $gutter * 2;
  font-size: 20px;

  font-family: $font-primary;
  padding: $gutter;
  background-color: $color-dark-grey;
  border-radius: $corner-radius;
  border: none;
  color: $color-white;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 3px 3px 2px rgba(255, 255, 255, 1);
  border: 2px solid white;
  &:hover {
    background-color: $color-white;
    color: $color-black;

  }
}

h1 {
  font-size: 50px;
  font-family: $font-secondary;
}

h2{
  font-weight: normal;
}

a {
  text-decoration: none;
  color: $color-blue;
  font-weight: 600;;
}

.font{
  &.prim{
    font-family: $font-primary;
  }
  &.sec{
    font-family: $font-secondary;
  }
  &.tert{
    font-family: $font-tertiary;
    font-size: 0.95em;
  }
  &.italic{
    font-style: italic;
  }

  &.align-left{
    text-align: left;
  }
}

//-----
// App
.App {
  //background: linear-gradient(rgb(0, 0, 0), rgb(31, 28, 24));
  text-align: center;
  display: flex;
  flex-direction: column;

  background-color: $background-color;
  min-height: 100vh;
  color: $color-white;
  align-items: center;
  justify-content: flex-start;
  
  font-family: $font-primary;
  font-weight: 200;
}

.app-container {
  display: flex;
  flex-grow: 1;
  @include levels(12);
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.app-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.font-basker {
  font-family: $font-tertiary !important;
  font-style: italic !important;
}

::selection {
  background: $color-yellow;
  color: $color-black;
}

.App a:hover {
  color: $color-yellow;
}

//--------
// components

.page-container {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  height: inherit;
  flex-direction: column;
}

.nav {
  display: flex;
  justify-content: space-between;
  border-left: 2px solid $background-color;
  align-items: center;
  width: calc(100% - 2px);

  .icon,
  .hamburger {
    z-index: 9999;
    padding: $gutter $gutter * 2;
  }

  .icon {
    border-bottom: 2px solid $background-color;
  }
  &.border {
    border-bottom: 2px solid $color-white;
  }

  @include breakpoint(sm) {
    .icon,
  .hamburger {
    
    padding: $gutter/2 $gutter;
    
  }
  }

  @include breakpoint(xs) {

    svg {
      height: 48px;
      
    }

    .hamburger {
       
      padding-left: 0 !important;
    }
    .icon,
    .hamburger {
      
      padding: $gutter $gutter;
      
    }

  }
}

.group-icon{
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.menu {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: $gutter * 3;
  font-size: 70px;
  font-family: $font-secondary;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  border-left: 2px solid white;

  @include levels(12);

  .links {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint(sm) {
    font-size: 60px;
    padding: $gutter;
  }
}

.menu a {
  color: $color-white;
  width: fit-content;
  text-decoration: none;
}

.info-card {
  display: flex;
  flex-direction: column;
  gap: $gutter / 2;
  padding: $gutter / 2;

  text-align: left;
  flex-grow: 1;

  h3 {
    font-family: $font-tertiary;
    font-style: italic;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 20px;
    margin: 0;
    padding-left: $gutter/2;
  }

  .infocard-content {
    font-family: $font-primary;
    font-weight: 400;
    font-size: 16px;
    background-color: $background-color;
    border-radius: $corner-radius;
    padding: $gutter;
    flex-grow: 1;
    margin-top: 0;

    ul{
      margin : 0;
    }

    div {
      p:first-child {
        margin-top: 4px;
      }
    }

    &.stack {
      margin-top: 0;
      margin-left: 0;
      display: flex;
      flex-wrap: wrap;
      gap: $gutter * 2;
      align-items: flex-start;
    }
  }
}

.credentials-card {
  display: flex;
  flex-direction: column;
  gap: $gutter/3;
  padding: $gutter;
  padding: 0;
  font-weight: 400;
  font-size: 20px;
  font-family: $font-secondary;
  font-style: normal;
  color: $color-white;

  span {
    font-size: 16px;
  }
  .social-links {
    display: flex;
    gap: $gutter;
    align-items: center;
    display: flex;
    gap: $gutter;
  }
  font-weight: 400;
  font-size: 20px;
}

.school-card {
  display: flex;
  gap: $gutter;
  padding: $gutter;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  p {
    width: 400px;
    text-align: center;
  }

  img:first-child {
    width: 120px;
  }

  @include breakpoint(xs) {
    img:first-child {
      width: 60px;
    }

    img:last-child {
      width: 48px;
    }
    p {
      font-size: 18px;
    }
  }

  &.dev{
    font-family: $font-secondary;
  }

  &.design{
    font-family: $font-tertiary;
    font-style: italic;
  }
}

.stack-card {
  display: flex;
  align-items: center;
  gap: $gutter / 4;
}

.journey-card {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2fr;
  //grid-template-rows: 400px;
  padding-left: $gutter * 2;
  border-left: 2px solid white;
  padding-bottom: $gutter * 2;

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, 0%);
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: $gutter;
    text-align: left;

    h2 {
      margin-top: 0;
    }

    p {
      font-family: $font-primary;
      font-weight: 200;
    }
  }

  .picture {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $gutter;
    img {
      width: auto;
      height: auto;
      max-height: 300px;
      max-width: 450px;
      width: 100%;
      object-fit: scale-down;
      border-radius: $corner-radius;
      overflow: hidden;
    }
  }
}

.project-card {
  display: flex;
  flex-direction: column;
  @include levels(3);
  margin: $gutter $gutter/2;
  background-color: $color-dark-grey;
  border-radius: $corner-radius;
  padding: $gutter;
  @include box-shadow;

  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $corner-radius;
  }


  > img{
    height: 200px;
  }
  .info {
    flex-grow: 1;
    display: grid;
    gap: $gutter/2;
    grid-template-columns: 2fr 1fr;
    padding: $gutter/2;
    padding-top: $gutter + $gutter/2;
    text-align: left;
    justify-content: start;
    align-items: start;
    justify-items: stretch;
   
    

    .project-card__title {
      font-family: $font-primary;
      font-weight: 400;
      font-style: normal;
      font-size: 20px;
    }
    .project-card__tags {
      padding: $gutter/2;
      justify-self: end;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: $gutter;
      width: fit-content;

      .project-card__tag{
        width: 40px;
        height: 40px;

        img{
          border-radius: 8px;
        }
      }
    }
  }

  @include breakpoint(lg) {
    .info{
      .project-card__title{
        font-size: 20px;
      }
    }

    > img {
      height: 150px;
    }
    .project-card__tags {
 

      .project-card__tag{
        width: 30px;
        height: 30px;
      }
    }
  }

  @include breakpoint(md) {
    .info{

      grid-template-columns: 1fr 1fr;
      .project-card__title{
        font-size: 18px;
      }
    }

    .project-card__tags {
 

      .project-card__tag{
        width: 25px;
        height: 25px;
      }
    }
  }

  &:hover {
    background-color: #292828;
    
    box-shadow: 0 0 5px rgba(212, 168, 168, 0.5);
   
    
  }
}

//--------
// Pages

.Home {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  
  
  

  .heading{
    height: calc(100vh - 100px);
    position: relative;
    background: radial-gradient(circle at center, rgb(14, 13, 13) 20%, $color-black 50%);
    
    .scroller {
      width: 100px;
       height: 100px;
       position: absolute;
       bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
      
       img{
         width: 100%;
           height: 100%;
           object-fit: cover;
       }
     }
  }

  h1 {
    margin-top: 10vh;
    position: relative;
    transform: translate(0%, 78%);
    z-index: 2;

    span:first-child {
      font-size: 48px;
      font-family: $font-tertiary;
      font-weight: 400;
      font-style: italic;
    }

    span:last-child {
      font-size: 60px;
      font-family: $font-secondary;
      font-weight: 400;
      font-style: normal;
    }
  }

  .img-and-info-container {
    display: flex;
    gap: $gutter * 2;
    align-items: center;
    justify-content: center;
    padding: 0 $gutter + $level;
    .img {
      height: 300px;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $corner-radius;
      }
    }

    .info {
      flex-shrink: 1;
      flex-grow: 1;
      text-align: left;
      @include levels(6);
      z-index: 2;
      max-width: 400px;
      font-size: $font-size-lg ;
    
      

      span {
        font-weight: bolder;
      }
    }
  }

  .schools {
    padding-top: $gutter * 10;
    @include levels(10);
    margin-left: $gutter + $level;
    margin-right: $gutter + $level;
    .school-card:first-child {
      border-bottom: 2px solid white;
    }
  }

  .projects {
    @include levels(10);
    flex-grow: 1;
    margin-left: 104px;
    margin-right: 104px;
    margin-top: $gutter ;
    text-align: left;


    .header{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      h2{
        font-size: 50px;
        font-family: $font-secondary;
        margin: $gutter;
      }

      a{
        padding: $gutter;
        padding-bottom: $gutter/2;
      }

    }
   

    .target {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;

      .group-icon{
        width: 30px;
        height: 30px;
        vertical-align: middle;
      }
    }
  }

  @include breakpoint(md) {
      h1{
        span:first-child {
          font-size: 40px;
         
        }
    
        span:last-child {
          font-size: 48px;
          
        }
      }
  }

  @include breakpoint(sm) {

    h1{
      margin-top: -$gutter;
      span:first-child {
        font-size: 28px;
       
      }
  
      span:last-child {
        font-size: 36px;
        
      }
    }

    .img-and-info-container {
      display: flex;
      padding: 0 $gutter;
    }

    .schools,.projects {
      margin-left: $gutter;
      margin-right: $gutter;
    }
  }

  @include breakpoint(xs) {
    h1{
      span:first-child {
        font-size: 24px;
       
      }
  
      span:last-child {
        font-size: 32px;
        
      }
    }

    .img-and-info-container {
      display: flex;
      flex-direction: column;
      padding: 0 $gutter * 2;
      gap: 0;
    .info{
      text-align: center;
      
    }
    }

    .schools,.projects {
      margin-left: $gutter;
      margin-right: $gutter;
    }
  }

  @include breakpoint(xxs) {
    h1{
      transform: none;
    }
  }
}

.Project {
  margin: $gutter;

  > h1 {
    padding: $gutter;
    font-size: 50px;
    font-family: $font-primary;
  
    

    > img:first-of-type {
      width: 176px;
      height: 70px;
      object-fit: contain;
      
    }
  }

  .info-and-journey{
    display: grid;
    grid-template-columns: 21fr 34fr;
    gap: $gutter;
    > .info {
      height: fit-content;
      background-color: $color-dark-grey;
      border-radius: $corner-radius;
      padding: $gutter/4;
      @include box-shadow;
      .leerdoelen,
      .stack {
        display: flex;
        flex-direction: column;
      }
  
      .infocard-content {
        &.stack {
          display: flex;
          flex-direction: row;
  
          .stack-card{
            height: 40px;
  
            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
              border-radius: $corner-radius / 3;
              margin-right: $gutter / 2;
  
              
            }
          }
        }
      }
    }

    @include breakpoint(md) {
      grid-template-columns: 1fr;
      gap: 0;
      > .info {
        grid-template-columns: 1fr;
       
        
      }

    
    }
  }
  

 

  .reflection,.group, .result {
    margin: $gutter 0;
    background-color: $color-dark-grey;
    border-radius: $corner-radius;
    @include box-shadow;
    .info-card {
      grid-template-columns: 1fr 7fr;
    }
  }

  >h2{
    font-size: 50px;
    font-family: $font-secondary;
   
    margin: $gutter;
    margin-top: $gutter * 2;
  }
  .images{
    
    .gallery{
      display: flex;
      flex-wrap: wrap;
      gap: $gutter / 4;
      a{
        
        img{
          height: 200px !important;
          height: auto;
          object-fit: cover;
          border-radius: $corner-radius;
        }
      }
    }
  }

  .journeys {
    padding: $gutter * 2 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-left: $gutter;
    .journey-card {
      @include levels(8);
    }
  }

  @include breakpoint(md) {
    > h2 {
      font-size: 40px;

      img {
        height: 40px;
        
      }
    }

    p {
      font-size: 16px;
    }

    .info{
      grid-template-columns: 1fr;
     
    }

    .journeys {

   
      .journey-card {

        grid-template-columns: 1fr;
        padding-bottom: $gutter * 2;

        .info {
          gap: 0;
        }
      }
    }
    .images{
      .gallery{
  
        a{
          img{
            height: 100px !important;
          
          }
        }
      }
    }
  }
}

.Contact {
  text-align: left;
  padding: $gutter + $level;
  padding-bottom: 0;
  padding-top: $level;
  display: flex;
  flex-direction: column;

 

  p {
    font-size: 24px;
    font-family: $font-primary;
    @include levels(6);
  }

  .btn{
    @include levels(6);
  align-self: center;
  width: 100%;
  }

  @include breakpoint(xl) {
    padding: $gutter;
    padding-left: $gutter + $level;
  }

  @include breakpoint(md) {
    padding: $gutter;
    p {
      font-size: 20px;
    }
  }

  @include breakpoint(sm) {
    padding: $gutter;
    p {
      font-size: 16px;
    }
  }
}

.Cv{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
 align-items: center;

  .btn{
    @include levels(3);
  align-self: center;
  width: 100%;
  margin: 0;
  margin-bottom: 40px
  }
  img{
    margin: 0 $gutter *2;
    flex-grow: 1;
    
    @include levels(8);
 
  }

  @include breakpoint(xl) {
    margin: 0 $gutter;
    img{
      width: 100%;
    }
  }

  @include breakpoint(md) {
    h1{
      font-size: 40px;
    }

    .btn{
      font-size: 18px;

      margin-bottom: $gutter * 2;
    }
  }

  @include breakpoint(sm) {
    h1{
      font-size: 32px;
    }
  }
}

.ProjectList{
  align-self: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
  width: calc(100% - 2 * #{$gutter});
  align-items: center;
  justify-items: center;
  gap: $gutter;
  padding: $gutter;
  @include levels(10);

  .group-icon{
    width: 30px;
    height: 30px;
    vertical-align: middle;
  }

  @include breakpoint(md) {
    .project-card {
      > img{
        height: 200px;
      }
    }
  }
}


.react-multiple-carousel__arrow{
  border: 2px solid $color-white !important;
}


//--------
// Footer

.footer {
  padding: $gutter * 10 $gutter $gutter $gutter;
  width: calc(100% - 2 * #{$gutter * 3});
  .credentials-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    a{
      color: $color-white;
    }

    .social-links {
      svg {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: $corner-radius;
      }
    }
    
  }

  @include breakpoint(sm) {

    .credentials-card {
      flex-direction: column;
      gap: $gutter;

      .social-links {

        svg{
          color: $color-white;
        }

        a{
          width: 40px;
          height: 40px;
          
        
        }
      }
    }
    padding: $gutter $gutter $gutter $gutter;
  }
}

//----------
// animations

@keyframes morphHamburgerLvl1 {
  0% { 
      d: path("M13 25L59 20");
  }
  100% { 
      d: path("M14.5 16L57 38");
  }
}

@keyframes morphHamburgerLvl2 {
  0% { 
      d: path("M11 34L57 29");
  }
  100% { 
      d: path("M9 43L63 11");
  }
}

@keyframes morphHamburgerLvl3 {
  0% { 
      d: path("M15 16L63 11");
  }
  100% { 
      d: path("M15 16L57 38");
  }
}

@keyframes morphHamburgerLvl4 {
  0% { 
      d: path("hamburger-lvl4");
  }
  100% { 
      d: path("M8.5 43L63 11");
  }
}


// animate burgermenue
@for $i from 1 through 4 {
  @include animateHamburger(#{$i});
  @include reverseHamburger(#{$i});
}
