// --- Typography ---
$font-size-base: 18px;
$font-size-sm: 16px;
$font-size-lg: 20px;
$font-size-xl: 24px;
$font-size-xxl: 32px;



// --- Border ---
// corner radius
$corner-radius: 16px;
$corner-radius-sm: 8px;


// --- breakpoints ---
$xxsBreakpoint: 375px;
$xsBreakpoint: 432px;
$smBreakpoint: 576px;
$mdBreakpoint: 768px;
$lgBreakpoint: 992px;
$xlBreakpoint: 1200px;
$xxlBreakpoint: 1400px;

$gutter : 16px;
$level : 104px;

// --- Colors ---
// head colors
$color-white: #FFFFF3;
$color-black: #020202;
$color-dark-grey: #201e1e;
$color-POTY24: #FFBE98;
$color-yellow: #ECEA61;
$color-blue: #4C73FE;


$font-primary: "Open Sans", sans-serif;
$font-secondary: "Bakbak One", sans-serif;
$font-tertiary: "Libre Baskerville", sans-serif;


$background-color: #020202;

// bezier curve
$animation-curve: cubic-bezier(0.65,0.05,0.35,1);






