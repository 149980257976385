@import './variables';

@mixin clickable{
    cursor: pointer;
  }


  

@mixin levels($amount) {
  max-width: $level * $amount + $gutter * ($amount - 1);
  
}


@mixin flex-center ($direction: row) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}


  // --- Breakpoints ---

  @mixin breakpoint($point) {
    @if $point == xxs {
      @media (max-width: $xxsBreakpoint) { @content; }
    }
    @if $point == xs {
      @media (max-width: $xsBreakpoint) { @content; }
    }
    @if $point == sm {
      @media (max-width: $smBreakpoint) { @content; }
    }
    @if $point == md {
      @media (max-width: $mdBreakpoint) { @content; }
    }
    @if $point == lg {
      @media (max-width: $lgBreakpoint) { @content; }
    }
    @if $point == xl {
      @media (max-width: $xlBreakpoint) { @content; }
    }
    @if $point == xxl {
      @media (max-width: $xxlBreakpoint) { @content; }
    }
  }
  

  @mixin animateHamburger($lvl) {
    .hamburger.animate #hamburger-lvl#{$lvl} {
      animation: morphHamburgerLvl#{$lvl} 0.5s $animation-curve forwards;
    }
  }
  
  @mixin reverseHamburger($lvl) {
    .hamburger.reverse #hamburger-lvl#{$lvl} {
      animation: morphHamburgerLvl#{$lvl} 0.5s $animation-curve alternate-reverse forwards;
    }
  }


  @mixin box-shadow {
    box-shadow: 0 0 5px rgba(110, 106, 106, 0.5);
  }